.shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #333; /* Dark background color */
    background: linear-gradient(to right, #444 8%, #333 18%, #444 33%); /* Dark gradient */
    background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

.media {
    height: 80%;
}

.p-32 {
    padding: 32px;
}

.title-line {
    height: 24px;
    width: 100%;
    margin-bottom: 12px;
    border-radius: 20px;
    background-color: #555; /* Dark background color */
}

.content-line {
    height: 8px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #666; /* Dark background color */
}

.end {
    width: 40%;
}
