@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=La+Belle+Aurore&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&display=swap');

@layer components {
  .section_heading {
    @apply text-[0.9375rem] font-medium lg:flex mb-3 lg:mb-0;
  }

  .section_padding_lg{
    @apply py-8
  }

  .section_padding_sm{
    @apply py-4
  }

  .section_gap_lg{
    @apply gap-16
  }

  .section_gap_sm{
    @apply gap-8
  }

  .input_label_padding {
    @apply px-3
  }
}



.bodydiv{
  font-family: 'Hanken Grotesk', sans-serif !important;
}

.arrow{
  z-index: 1;
}

.slick-prev,
.slick-next {
  display: none !important;
}



.congrats_message{
  font-family: 'Arapey', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

.custom-dashed-border {
    border-style: dashed;
    border-width: 2px;
    border-image: repeating-linear-gradient(to right, #dad3c3 5px, #dad3c3 5px, transparent 5px, transparent 5px) 1;
  }

  .shadow{
    -webkit-box-shadow: 0 -3px 10px 3px #dddddd;
-moz-box-shadow: 0 -3px 10px 3px #dddddd;
box-shadow: 0 -3px 10px 3px #dddddd;
  }

.hideme{
    display: none;  
}



/* .rmdp-input{

  padding: 1rem 0.25rem !important;
  width: 170px !important;
} */


.tabs_list {
  display: flex;
  justify-content: space-around;
  padding: 0;
  list-style: none;
  position: relative;
  /* padding-bottom: 5px; */
  border-bottom: 1px solid rgba(178 172 172);
}

.tabs_list li {
  padding: 5px 8px;
}

.tabs_list li.activetab {
  /* padding: 5px 8px; */
  /* border-bottom: 1px solid black; */
  /* transition: all 0.5s; */
  position: relative;
}

.tabs_list li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 0;
}

.tabs_list li.activetab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #000000;
  opacity: 1;
  transition: all 0.5s;
}

.ql-toolbar{
  border-top-right-radius: 0.75rem;
  background-color: rgba(248, 246, 242, 0.30);
  border: 2px solid #e5e7eb !important;
  border-top-left-radius: 0.75rem;
}
.ql-container{
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  /* border-radius: 0.75rem; */
  background-color: rgba(248, 246, 242, 0.30);
  border: 2px solid #e5e7eb !important;
  border-top: none !important;
}

.ql-editor{
  min-height: 10rem;
  background-color: white;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.custom-class{
  width: 100px !important;
}



.rmdp-week-day{
  color: #045540 !important;
}

.dots {
  width: 9.6px;
  height: 9.6px;
  background: #ffffff;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 16px 0,-16px 0;
  animation: dots-u8fzftsm 1s infinite linear alternate;
}



.fade-out{
  /* opacity: 0; */
  animation: fade-out 1s;
}

.fade-in{
  opacity: 1;
  animation: fade-in 1s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    /* scale: 1; */
  }
  100% {
    opacity: 0;
    /* scale: 1.5; */
  }
}

.bottom_controls{
 
box-shadow: -1px -3px 5px 0px rgba(222,222,222,1);
}



@keyframes dots-u8fzftsm {
  0% {
     box-shadow: 16px 0,-16px 0;
     
  }

  33% {
     box-shadow: 16px 0,-16px 0 rgba(255,255,255,0.13);
     background: rgba(255,255,255,0.13);
  }

  66% {
     box-shadow: 16px 0 rgba(255,255,255,0.13),-16px 0;
     background: rgba(255,255,255,0.13);
  }
}

@media screen and (max-width: 768px){
  .ql-container{
    min-height: 5rem;
  }

  .ql-editor{
    min-height: 5rem;
  }
}



