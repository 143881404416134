@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Hanken Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: local('Helvetica Neue'), url(./fonts/helvetica-neue-5/HelveticaNeue-Regular.otf) format('woff');
}
@font-face {
  font-family: 'Helvetica Neue Medium';
  src: local('Helvetica Neue'), url(./fonts/helvetica-neue-5/HelveticaNeueMedium.otf) format('woff');
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: local('Helvetica Neue'), url(./fonts/helvetica-neue-5/HelveticaNeueLight.otf) format('woff');
}