/* styles.css */
.navbar {
    background-color: #333;
    color: #fff;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
  }
  
  .navbar-links ul {
    list-style: none;
    display: flex;
  }
  
  .navbar-links li {
    margin-right: 20px;
  }
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-icons {
    display: flex;
    align-items: center;
  }
  
  .navbar-icons input {
    padding: 8px;
    border-radius: 4px;
    border: none;
    margin-right: 10px;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger.active .line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active .line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .line {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background-color: #333;
      transition: all 0.3s ease-in;
      padding: 20px;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .navbar-icons {
      display: flex;
    }
  
    .hamburger {
      display: flex;
    }
  }
  